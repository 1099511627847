.Preloader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  background-color: #fff;
}

.Preloader h1 {
  color: var(--Secondary);
  animation: color-change 2s infinite;
}

@keyframes color-change {
  0% {
    color: var(--Secondary);
  }
  50% {
    color: var(--Primary);
  }
  100% {
    color: var(--Secondary);
  }
}
