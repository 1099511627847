.SearchBarContanier {
  width: 100%;
  height: 7%;
  background-color: var(--Primary);
  border: 1px solid #f1f6f9;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2px 10px;
}

.SearchBarComponent {
  height: 70%;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.SearchBarComponent:nth-child(1),
.SearchBarComponent:nth-child(2) {
  border-right: 1px solid var(--Terciary);
}

.SearchBarIcon {
  background-color: var(--Primary);
  color: #fff;
  padding: 15px;
  border-radius: 3px;
}

.SearchBarIcon:hover {
  background-color: var(--Primary);
  cursor: pointer;
}

.SearchBarSelect {
  border: none;
  padding: 0;
  color: var(--Primary);
}

.SearchBarSelect:hover {
  cursor: pointer;
}

.SearchBarComponentTitle {
  font-size: calc(30% + 1vw);
  font-weight: 400;
  color: var(--Primary);
  margin-left: 3px;
}

.SearchBarHuespedes {
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

.SearchBarHuespedesIcon {
  background-color: #fff;
  color: var(--Primary);
}

.SearchBarHuespedesIcon:hover {
  cursor: pointer;
  color: var(--Secondary);
}

.SearchBarHuespedes p {
  color: var(--Primary);
}
