.HeaderNav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  height: 10%;
  color: var(--Terciary);
  font-weight: 400;
  font-size: calc(40% + 0.5vw);
  background-color: var(--Primary);
  opacity: 0.7;
  position: sticky;
}

.HeaderContainer1 {
  width: 30%;
  height: 100%;
}
.HeaderContainer2 {
  width: 30%;
  height: 100%;

  display: flex;
  gap: 5vw;
  justify-content: center;
  align-items: center;
}

.HeaderNavImg {
  height: 90%;
}

.HeaderContainer3 {
  width: 30%;
  height: 100%;
  padding-right: 15px;

  display: flex;
  gap: 5vw;
  justify-content: right;
  align-items: center;
}

.HeaderMenuIcon {
  background-color: var(--Primary);
}

.HeaderButton {
  width: 100px;
  border-radius: 5px;
  height: 3vh;
  border: 0px;
  margin-left: 10px;
}

.HeaderButtonLight {
  background-color: var(--Secondary);
}

.HeaderButtonDark {
  background-color: var(--Terciary);
}

.HeaderButtonLight:hover {
  background-color: var(--Terciary);
  cursor: pointer;
}

.HeaderButtonDark:hover {
  background-color: var(--Secondary);
  cursor: pointer;
}

.HeaderLink {
  text-decoration: none;
  color: inherit;
  font-weight: 400;
}
