@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&family=Ysabeau:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

:root {
  --Primary: #b0a783;
  --Secondary: #e4dccf;
  --Terciary: #f9f5eb;
  --BoxShadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --lightContrast: #eeeeee;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ysabeau", sans-serif;
}

html {
  height: 100vh;
  width: 100vw;
}

body {
  background-color: white;
}
