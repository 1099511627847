.InfoSectionContainer {
  width: 100%;
  height: 70vh;
  min-height: 40vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3vh;
}

.InfoSectionTitle {
  color: var(--Primary);
  font-weight: 100;
  font-size: calc(100% + 2vw);
}

.InfoSectionHr {
  width: 7vw;
  height: 2px;
  border-width: 0;
  color: var(--Primary);
  background-color: var(--Primary);
  margin-top: 1vh;
}

.contrast {
  color: #fff;
  background-color: var(--Secondary);
}

.plain {
  color: var(--Primary);
  background-color: #fff;
}

.InfoSectionContent {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4vw;
}
