/* ######### Computer ########### */

/* CSS Source File for Home Cards Divided in 3 Sections
  1. Cards
  2. Slider
  3. List
*/

/* -------------- Cards ------------ */

.HomeCardContainer {
  width: 20vw;
  min-width: 300px;
  height: 40vh;
  min-height: 370px;
  margin: 20px;
  background-color: white;
  border-radius: 2px;
  border: 0.1px solid #f1f6f9;
  box-shadow: var(--BoxShadow);
}

.HomeCardInfoSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px;
  gap: 10px;
}

.HomeCardTitle {
  font-size: calc(100% + 0.4vw);
  font-weight: 100;
  color: var(--Primary);
  margin: 5px;
}

.HomeCardText {
  font-size: calc(70% + 0.3vw);
  font-weight: 100;
  color: var(--Secondary);
  margin-left: 5px;
}

.HomeCardIcon {
  color: grey;
}

.HomeCardButtonCont {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.HomeCardLink {
  width: 50%;
  font: bold 11px Arial;
  text-decoration: none;
  padding: 10px 10px;
  background-color: var(--Secondary);
  text-align: center;
  color: #fff;
}

.HomeCardLink:hover {
  background-color: var(--Primary);
  cursor: pointer;
}

/* -------------- Home Card Slider ------------ */

.sliderStyles {
  height: 65%;
  position: relative;
}

.slideStyles {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-position: center;
  background-size: cover;
  background-color: white;
}

.leftArrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  font-size: 3rem;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.rightArrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  font-size: 3rem;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.rightArrowStyles:hover {
  color: var(--Secondary);
}

.leftArrowStyles:hover {
  color: var(--Secondary);
}

/* -------------- Home Lists------------ */

.container {
  margin: auto;
}

.HomeListSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  width: 100%;
}
