.sidebar {
  width: 260px;
  height: 100vh;
  background-color: var(--Primary);
  position: sticky;
  top: 0;
}

.sidebarWrapper {
  padding: 10px 25px;
  color: white;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.menuLogo {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconLogo {
  width: calc(6vw + 50px);
}

.sidebarList {
  list-style: none;
  padding: 5px;
  margin-top: 1vh;
}

.sidebarItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 1.05rem;
  font-weight: 300;
  color: var(--Terciary);
}

.sidebarListTitle {
  color: var(--Terciary);
  font-size: 0.9rem;
  margin: 20px 0 5px 0;
}

.active,
.sidebarItem:hover {
  color: var(--Secondary);
  font-weight: 700;
}

.space {
  margin-right: 10px;
}

a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 5px;
}

.sidebarItem a:hover {
  color: var(--Secondary);
}
