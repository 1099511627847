/* #################### Smartphone ###################### */

/* CSS Source File for Home Cards Divided in 3 Sections
  1. Cards
  2. Slider
  3. List
*/

@media all and (max-device-width: 480px) {
  /* -------------- Cards ------------ */

  .HomeCardContainer {
    width: 90vw;
    height: 40vh;
    min-height: 320px;
    margin: 20px;
    background-color: white;
    border-radius: 2px;
  }

  .HomeCardTitle {
    font-size: calc(100% + 1vw);
    color: var(--Titles);
    margin-left: 5px;
  }

  .HomeCardText {
    font-size: calc(70% + 1vw);
    color: var(--Text);
    margin-left: 5px;
  }

  /* -------------- Home Card Slider ------------ */

  .sliderStyles {
    height: 75%;
    position: relative;
  }

  .slideStyles {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-position: center;
    background-size: cover;
    background-color: white;
  }

  .leftArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    font-size: 3rem;
    color: #fff;
    z-index: 1;
    cursor: pointer;
  }

  .rightArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    font-size: 3rem;
    color: #fff;
    z-index: 1;
    cursor: pointer;
  }

  /* -------------- Home Lists------------ */

  .container {
    margin: auto;
  }

  .HomeListSection {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    flex-wrap: wrap;
  }
}
