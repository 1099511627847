.BubbleContainer {
  width: 20vw;
  height: 15vh;
  background-color: var(--Secondary);
  box-shadow: var(--BoxShadow);
  border-radius: 10px;
  margin: 15px 15px 15px 0px;
  padding: 5px 20px;
  text-align: center;
  flex-grow: 1;
}

.BubbleContainer h1 {
  color: var(--Primary);
}

.BubbleContainer p {
  color: #fff;
  font-size: 4rem;
}
