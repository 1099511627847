.InfoCardContainer {
  width: 25vw;
  height: 45vh;
  background-color: #fff;
  box-shadow: var(--BoxShadow);
}

.InfoCardContainer:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.InfoCardPhoto {
  width: 100%;
  height: 75%;
  background-position: center;
  background-size: cover;
}

.InfoCardContent {
  padding-left: 10px;
  padding-top: 15px;
}

.InfoCardTitle {
  font-size: calc(80% + 1vw);
  font-weight: 300;
}

.InfoCardText {
  color: var(--Secondary);
  font-size: calc(30% + 1vw);
}
