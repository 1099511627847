.FooterContainer {
  width: 100%;
  height: 10vh;
  background-color: var(--Primary);
  display: flex;
  align-items: center;
  justify-items: center;
}

.FooterContainer1 {
  height: 100%;
  background-color: var(--Primary);
  display: flex;
  align-items: center;
  justify-items: center;
  flex: 1;
  flex-direction: column;
}

.FooterContainer2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  flex: 1;
}

.FooterContainer3 {
  height: 100%;
  background-color: var(--Primary);
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  flex: 1;
}

.FooterC2Social {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 20px;
  justify-content: flex-start;
  align-items: center;
}

.FooterIcon {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  gap: 5px;
}

.FooterIcon:hover {
  color: var(--Secondary);
  cursor: pointer;
}
