.StatContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.kpiContanier {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.graphContainer {
  display: flex;
  width: 100%;
  height: 40vh;
  align-items: center;
  gap: 20px;
}

.lineContainer {
  width: 50%;
  height: 100%;
}
