.sharedLayout {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #fff;
}

.containerRight {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 40px;
}
