.FormsBody {
  width: 100%;
  height: 100vh;
  background-color: var(--Terciary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormBox {
  width: 60vw;
  height: 55vh;
  max-width: 55rem;
  max-height: 60rem;
  min-width: 35rem;
  min-height: 30rem;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: var(--BoxShadow);
  display: flex;
}

.FormInput {
  height: 100%;
  width: 60%;
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormInput h1 {
  color: var(--Primary);
  font-size: 80px;
}

.FormDecoration {
  height: 100%;
  width: 40%;
  border-radius: 0 5px 5px 0;
  background-color: var(--Primary);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
}

.FormDecoration h1 {
  color: #fff;
  font-size: 20px;
}

.FormDecoration img {
  width: 80%;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 90%;
  height: 80%;
}

.Form label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--Primary);
  font-weight: 600;
  margin-top: 0;
}

.FormText {
  margin-bottom: 10px;
  border-radius: 5px;
  border: 2px solid var(--Secondary);
  width: 70%;
  height: 40px;
  box-shadow: var(--BoxShadow);
  padding-left: 10px;
}

.FormButton {
  background-color: var(--Primary);
  border: 0px solid var(--Primary);
  color: #fff;
  width: 70%;
  height: 40px;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: var(--BoxShadow);
}

.FormButton:hover {
  cursor: pointer;
  background-color: var(--Terciary);
  color: var(--Primary);
  border: 1px solid var(--Primary);
}

.FormTextR {
  margin-bottom: 8px;
  border-radius: 5px;
  border: 2px solid var(--Secondary);
  width: 70%;
  height: 30px;
  box-shadow: var(--BoxShadow);
  padding-left: 10px;
}

.Form p {
  margin-top: 7px;
  font-size: 12px;
}
.FormLink {
  color: var(--Primary);
  text-decoration: none;
  text-decoration: underline;
  font-weight: 700;
  margin-top: 5px;
}
.FormLink:hover {
  color: var(--Secondary);
  cursor: pointer;
}

.FormHome {
  position: relative;
  bottom: 24vh;
  left: 3vw;
}

.FormHomeIcon {
  color: var(--Primary);
}

.FormHomeIcon:hover {
  color: var(--Secondary);
}
