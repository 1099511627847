.HeaderContainer {
  width: 100%;
  height: 55vh;
  background-image: url("../../../public/img/Cover.jpg");
  background-position: center;
  background-size: cover;
  overflow-y: hidden;
  margin-bottom: 0px;
  opacity: 1;
}

.HeaderHr {
  width: 90vw;
  height: 1px;
  border-width: 0;
  color: var(--Terciary);
  background-color: var(--Terciary);
  margin: 0 auto;
}

.FullWidthContainer {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.HeaderText {
  width: 45vw;
  padding-left: 5vw;
}

.HeaderText h1 {
  color: var(--Terciary);
  font-size: calc(100% + 1.5vw);
}

.HeaderSearchBar {
  display: flex;
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 55%;
  transform: translate(0, -50%);
  min-height: 70px;
}

.HeaderTitleHr {
  width: 7vw;
  height: 4px;
  border-width: 0;
  color: var(--Titles);
  background-color: var(--Primary);
  margin-top: 2vh;
}
